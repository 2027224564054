/* CustomCarousel.css */
.custom-carousel-container {
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 0 0 20px 20px; /* Adicione o border-radius inferior */
  }
  
  .react-responsive-carousel {
    max-height: none !important;
    height: auto !important;
  }
  
  .react-responsive-carousel .carousel .slider-wrapper {
    padding-bottom: 0 !important;
  }
  