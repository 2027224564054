.nav-menu {
    background-color: #fff;
    width: 70%;
    /* Aumente a largura para 80% */
    margin: 10px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 10px 100px;
    /* Reduza o padding para manter a altura total */
    position: relative;
    border-radius: 25px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.logo {
    width: 200px;
    /* Largura desejada */
    height: auto;
    /* Altura automática para manter a proporção original */
}

.btn {
    font-weight: bold;
}

.nav-menu .menu-container {
    display: flex;
    width: 60%; /* Ajuste a largura para acomodar melhor o conteúdo */
    gap: 40px;
    flex-grow: 1;
    justify-content: center;
}

.nav-menu .menu-item {
    color: #333;
    text-decoration: none;
    font-weight: normal;
}

.nav-menu .menu-item:hover {
    color: #ff7b23;
}

.nav-menu .btn {
    background: linear-gradient(to right, #FFA600, #FF6600);
    /* Gradiente de laranja claro (#FFA600) para laranja mais escuro (#FF6600) */
    color: #fff;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.3s ease;
    /* Alterei de background-color para background para animar o gradiente */
}

.nav-menu .btn:hover {
    background: linear-gradient(to right, #FF6600, #FFA600);
    /* Inversão das cores no hover */
    box-shadow: 7px 5px 56px -14px #ff7b23;
}

.nav-menu .btn:active {
    transform: scale(0.98);
    box-shadow: 7px 5px 56px -10px #ff7b23;
}

.nav-menu .footer {
    background-color: orange;
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 50px 50px;
}

.extra-items {
    gap: 20px;
    width: 70%;
    margin: 30px auto 0;
    display: flex;
    align-items: center;
}

.extra-items a {
    font-size: 14px;
    color: #333;
    text-decoration: none;
}

.extra-items a:hover {
    color: #ff7b23;
}

/* Estilos para o modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Valor alto para garantir que o modal fique por cima de todos os elementos */
}

.modal {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 80%;
    text-align: center;
    position: relative;
    z-index: 1001;
    /* Valor maior do que o z-index do modal-overlay */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    /* Aumenta o tamanho do ícone de fechar */
    color: #888;
    /* Cor do ícone de fechar */
}

.close:hover {
    color: #000;
    /* Altera a cor do ícone de fechar ao passar o mouse por cima */
}

p {
    margin: 0;
}