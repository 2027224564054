.section-barra-boxes {
    background-color: #ff5e00; /* Cor de fundo laranja */
    width: 70%;
    margin: auto;
    padding: 10px;
    border-radius: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */

}

.section-barra-boxes p {
    color: #fff;
    text-align: center; /* Centraliza o texto */
    margin: 0; /* Remove margens padrão */
}

.section-barra-boxes .section-title {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    overflow: hidden; /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
    flex-wrap: wrap; /* Permite que os boxes quebrem para a próxima linha em telas menores */
}

.section-barra-boxes .btn {
    background: linear-gradient(to right, #ffe3cd, #fdc6a1); /* Gradiente de laranja claro (#FFA600) para laranja mais escuro (#FF6600) */
    color: #ff6600;
    border: none;
    padding: 10px ;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.3s ease; /* Alterei de background-color para background para animar o gradiente */
    margin-left: 30px;
}

.section-barra-boxes .btn:hover {
    background: linear-gradient(to right, #ff9456, #ffbc40); /* Inversão das cores no hover */
    color: #fff;
    transition: 0.5;
}

.section-barra-boxes .btn:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #def325;
   }